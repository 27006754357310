import React from "react";
import "./Animation.scss";

const Animation = () => {
  return (
    <div>
      <div className="scene">
        <div className="book-wrap">
          <div className="left-side">
            <div className="book-cover-left"></div>
            <div className="layer1">
              <div className="page-left"></div>
            </div>
            <div className="layer2">
              <div className="page-left"></div>
            </div>
            <div className="layer3">
              <div className="page-left"></div>
            </div>
            <div className="layer4">
              <div className="page-left"></div>
            </div>
            <div className="layer-text">
              <div className="page-left-2">
                <div className="corner"></div>
                <div className="corner2"></div>
                <div className="corner-fold"></div>
                <div className="page-text w-richtext">
                  <h3>
                    <strong>Gujarat</strong>
                  </h3>
                  <h6 className="mt-2">
                    BY 
                    <a href="/" target="_blank">
                      UniOnBoard
                    </a>
                  </h6>
                  <br />
                  <p>L.D‍</p>
                  <p>BVM</p>
                  <p>SVNIT</p>
                  <p>DDU</p>
                  <p>DAIICT</p>
                  <p>ADIT</p>
                  <p>NIRMA</p>
                  <p>GIT</p>
                  <p>SVIT</p>
                  <p>Maliba</p>
                </div>
              </div>
            </div>
          </div>
          <div className="center"></div>
          <div className="right-side">
            <div className="book-cover-right"></div>
            <div className="layer1">
              <div className="page-right"></div>
            </div>
            <div className="layer2 right">
              <div className="page-right"></div>
            </div>
            <div className="layer3 right">
              <div className="page-right"></div>
            </div>
            <div className="layer4 right">
              <div className="page-right"></div>
            </div>
            <div className="layer-text right">
              <div className="page-right-2">
                <div className="page-text w-richtext">
                  <h3>
                    <strong>Colleges</strong>
                  </h3>
                  <h6 className="mt-2">
                    BY 
                    <a href="/" target="_blank">
                      UniOnBoard
                    </a>
                  </h6>
                  <br />
                  <p>SCET</p>
                  <p>Mahavir</p>
                  <p>VGEC</p>
                  <p>GEC</p>
                  <p>GCET</p>
                  <p>SPU</p>
                  <p>IIT</p>
                  <p>IIM</p>
                  <p>PDPU</p>
                  <p>Changa</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
