import React from "react";
import "./UpdateProfile.css";
const UpdateProfile = () => {
  return (
    <>
      <h1>Update Profile</h1>
    </>
  );
};

export default UpdateProfile;
